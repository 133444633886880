import { api } from './api'

const ClientRepository = {
    index (query = {}) {
        return api().$get('clients', query)
    },

    show (clientId) {
        return api().$get(`clients/${clientId}`)
    },

    update (clientId, payload) {
        return api().$put(`clients/${clientId}`, payload)
    },

    create (payload) {
        return api().$post('clients', payload)
    },

    delete (clientId) {
        return api().$delete(`clients/${clientId}`)
    },

    attachContact (clientId, payload) {
        return api().$post(`clients/${clientId}/contacts`, payload)
    },

    detachContact (clientId, contactId) {
        return api().$delete(`clients/${clientId}/contacts/${contactId}`)
    },

    uploadContracts (clientId, payload) {
        return api().$post(`clients/${clientId}/upload-contracts`, payload)
    },

    // STATS
    revenueSecured (clientId) {
        return api().$get(`/stats/clients/${clientId}/amount-secured`)
    },

    revenueTargetted (clientId) {
        return api().$get(`/stats/clients/${clientId}/amount-targetted`)
    },

    // CLIENT PRODUCTS
    getProducts (clientId, query = {}) {
        return api().$get(`clients/${clientId}/products`, query)
    },

    createProduct (clientId, payload) {
        return api().$post(`clients/${clientId}/products`, payload)
    },

    updateProduct (clientId, clientProductId, payload) {
        return api().$put(`clients/${clientId}/products/${clientProductId}`, payload)
    },

    deleteProduct (clientId, clientProductId) {
        return api().$delete(`clients/${clientId}/products/${clientProductId}`)
    },

    // CLIENT FUNDER COMPANIES
    getFunders (clientId, query = {}) {
        return api().$get(`clients/${clientId}/funder-companies`, query)
    },

    createFunder (clientId, payload) {
        return api().$post(`clients/${clientId}/funder-companies`, payload)
    },

    updateFunder (clientId, clientFunderId, payload) {
        return api().$put(`clients/${clientId}/funder-companies/${clientFunderId}`, payload)
    },

    deleteFunder (clientId, clientFunderId) {
        return api().$delete(`clients/${clientId}/funder-companies/${clientFunderId}`)
    },

    // CLIENT ACQUISITIONS
    getAcquisitionsAsClientCompany (clientId, query = {}) {
        return api().$get(`clients/${clientId}/acquisitions-as-client-company`, query)
    },

    getAcquisitions (clientId, query = {}) {
        return api().$get(`clients/${clientId}/acquisitions`, query)
    },

    exportAcquisitions (clientId, query = {}) {
        return api().$get(`clients/${clientId}/acquisitions/export`, query, {
            download: true
        })
    },

    createAcquisition (clientId, payload) {
        return api().$post(`clients/${clientId}/acquisitions`, payload)
    },

    updateAcquisition (clientId, acquisitionId, payload) {
        return api().$put(`clients/${clientId}/acquisitions/${acquisitionId}`, payload)
    },

    deleteAcquisition (clientId, acquisitionId) {
        return api().$delete(`clients/${clientId}/acquisitions/${acquisitionId}`)
    },

    // CLIENT BROKER
    getBrokers (clientId, query = {}) {
        return api().$get(`clients/${clientId}/brokers`, query)
    },

    createBroker (clientId, payload) {
        return api().$post(`clients/${clientId}/brokers`, payload)
    },

    updateBroker (clientId, clientBrokerId, payload) {
        return api().$put(`clients/${clientId}/brokers/${clientBrokerId}`, payload)
    },

    deleteBroker (clientId, clientBrokerId) {
        return api().$delete(`clients/${clientId}/brokers/${clientBrokerId}`, { method: 'delete' })
    },

    // CLIENT BANK
    getBanks (clientId, query = {}) {
        return api().$get(`clients/${clientId}/banks`, query)
    },

    createBank (clientId, payload) {
        return api().$post(`clients/${clientId}/banks`, payload)
    },

    updateBank (clientId, clientBankId, payload) {
        return api().$put(`clients/${clientId}/banks/${clientBankId}`, payload)
    },

    deleteBank (clientId, clientBankId) {
        return api().$delete(`clients/${clientId}/banks/${clientBankId}`)
    },

    // CLIENT ADVISORY MISSION
    getAdvisoryMissions (clientId, query = {}) {
        return api().$get(`clients/${clientId}/advisory-missions`, query)
    },

    createAdvisoryMission (clientId, payload) {
        return api().$post(`clients/${clientId}/advisory-missions`, payload)
    },

    updateAdvisoryMission (clientId, advisoryMissionId, payload) {
        return api().$put(`clients/${clientId}/advisory-missions/${advisoryMissionId}`, payload)
    },

    deleteAdvisoryMission (clientId, advisoryMissionId) {
        return api().$delete(`clients/${clientId}/advisory-missions/${advisoryMissionId}`)
    },

    // CLIENT ONBOARDING
    getOnboardings (clientId, query = {}) {
        return api().$get(`clients/${clientId}/onboardings`, query)
    },

    createOnboarding (clientId, payload) {
        return api().$post(`clients/${clientId}/onboardings`, payload)
    },

    updateOnboarding (clientId, onboardingId, payload) {
        return api().$put(`clients/${clientId}/onboardings/${onboardingId}`, payload)
    },

    deleteOnboarding (clientId, onboardingId) {
        return api().$delete(`clients/${clientId}/onboardings/${onboardingId}`)
    },

    // CLIENT FAQ
    getQuestions (clientId, query = {}) {
        return api().$get(`clients/${clientId}/questions`, query)
    },

    createQuestion (clientId, payload) {
        return api().$post(`clients/${clientId}/questions`, payload)
    },

    updateQuestion (clientId, questionId, payload) {
        return api().$put(`clients/${clientId}/questions/${questionId}`, payload)
    },

    deleteQuestion (clientId, questionId) {
        return api().$delete(`clients/${clientId}/questions/${questionId}`)
    },

    getQuestionsUsers (clientId) {
        return api().$get(`clients/${clientId}/questions-users`)
    },

    attachQuestionsUser (clientId, payload) {
        return api().$post(`clients/${clientId}/questions-users`, payload)
    },

    removeQuestionsUser (clientId, userId) {
        return api().$delete(`clients/${clientId}/questions-users/${userId}`)
    },

    // timer
    logTimer (clientId, payload) {
        return api().$post(`clients/${clientId}/timer`, payload)
    },

    // User Permissions
    getUsers (clientId, query = {}) {
        return api().$get(`clients/${clientId}/users`, query)
    },

    attachUser (clientId, userId, payload = {}) {
        return api().$post(`clients/${clientId}/users/${userId}/attach`, payload)
    },

    detachUser (clientId, userId) {
        return api().$post(`clients/${clientId}/users/${userId}/detach`)
    },

    // Hubspot
    createFromHubspot (payload) {
        return api().$post('clients/create-from-hubspot', payload)
    }
}

export default ClientRepository
